import React, { useState } from "react"
import { SidebarProductData, SidebarResourceData } from "./SidebarData"
import sidebarStyle from "./SideBar.module.scss"
import Button from "../../../components/Atoms/Button/Button"
import SideNavItem from "../../Molecules/SideNavItem/SideNavItem"
import config, { backgroundColor, themeColor } from "../../../utils/siteConfig"

//function SideBar()
const SideBar = props => {
  const [sidebar, setSidebar] = useState(true)

  const showSidebar = () => setSidebar(!sidebar)

  return (
    <>
      <div className={sidebarStyle.container}>
        <div className={sidebarStyle.navbar}>
          <Button
            className="btns"
            buttonStyle="btn--navSigninDark"
            buttonSize="btn--medium"
            onClick={showSidebar}
          >
            <div className={sidebarStyle.burgerLight}>
              <div class="line1"></div>
              <div class="line2"></div>
              <div class="line3"></div>
            </div>
          </Button>
        </div>
        <div className={sidebarStyle.sideContainer}>
          {sidebar === true ? (
            <nav className={sidebarStyle.navMenu.active}></nav>
          ) : (
            <nav className={sidebarStyle.navMenu}>
              <ul className={sidebarStyle.navMenuItems} onClick={showSidebar}>
                <div className={sidebarStyle.navbarToggle}>
                  <Button
                    className="btns"
                    buttonStyle="btn--navSigninDark"
                    buttonSize="btn--medium"
                  >
                    <div id="mdiv">
                      <div className={sidebarStyle.mdiv}>
                        <div className={sidebarStyle.md}></div>
                      </div>
                    </div>
                  </Button>
                </div>
                <h6>PRODUCTS</h6>
                <div className="rowControl">
                  <div className="row pt-2">
                    {SidebarProductData.map((item, index) => {
                      return (
                        <div className="col-3 pl-0">
                          <SideNavItem
                            href={item.path}
                            rel="nofollow noopener noreferrer"
                          >
                            <span
                              className={sidebarStyle.navSpan}
                              className="pl-3"
                            >
                              {item.title}
                            </span>
                          </SideNavItem>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <h6 className="pt-3">RESOURCES</h6>
                <div className="rowControl">
                  <div className="row pt-2">
                    {SidebarResourceData.map((item, index) => {
                      return (
                        <div className="col-3 pl-0">
                          <SideNavItem
                            href={item.path}
                            rel="nofollow noopener noreferrer"
                          >
                            <span
                              className={sidebarStyle.navSpan}
                              className="pl-3"
                            >
                              {item.title}
                            </span>
                          </SideNavItem>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="row justify-content-center pt-2">
                  <Button
                    className="btns"
                    buttonStyle="btn--navSigninLight"
                    buttonSize="btn--medium"
                    href={config.navigationURL_contactUs}
                  >
                    Sign in
                  </Button>
                </div>
              </ul>
            </nav>
          )}
        </div>
      </div>
    </>
  )
}

export default SideBar
