import React from "react"
import FooterNavItemStyles from "./FooterNavItem.module.scss"
export default function FooterNavItem(props) {
  return (
    <li className={FooterNavItemStyles.footerNavItem}>
        <a 
           href={props.href}
           rel={props.rel}
           target={props.target}
        >
          {props.children}
        </a>
    </li>
  )
}
