import React from "react"
import footerStyles from "./footer.module.scss"
import FooterNavItem from "../../Molecules/FooterNavItem/FooterNavItem"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import LanguageDetector from "../../Molecules/Language/LanguageDetector"
import Language from "../../Molecules/Language/Language"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons"
import config from "../../../utils/siteConfig"

const Footer = props => (
  <section className="bg-lightgray">
    <div className={footerStyles.footer}>
      <div className="row">
        <div className="col-lg-3">
          <div className={footerStyles.logoCol}>
            <div className="row pl-0">
              {/*Wunderloop logo*/}
              <ul className={footerStyles.footerWrapperUl}>
                <li className={footerStyles.footerWrapperUlStyleNone}>
                  <div className={footerStyles.footerLogoCol}>
                    <ul className={footerStyles.footerWrapperUlStyleNone}>
                      <div className={footerStyles.footerLogo}>
                        <a className={footerStyles.footerLogoFont} href="/">
                          <img
                            src={require("../../../images/Logo/wunderloop_favicon.svg")}
                            style={{ width: "60%" }}
                            alt="Wunderloop logo"
                          />
                        </a>
                      </div>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div className="row pl-0">
              {/*Languages Column*/}
              <div className={footerStyles.langPickerContainer}>
                <div className={footerStyles.langMenu}>
                  <div className={footerStyles.selectedLang}>
                    <FontAwesomeIcon
                      size="1x"
                      icon={faCommentAlt}
                      className="faCommentAlt"
                      color="#0a2540"
                    />
                    <LanguageDetector></LanguageDetector>
                  </div>
                  <ul>
                    <li>
                      <Language>English (United States)</Language>
                    </li>
                    <li>
                      <Language>Deutsch (German)</Language>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <ul
                className={
                  (footerStyles.footerWrapperUl, footerStyles.footercompany)
                }
              >
                <ul className={footerStyles.footerWrapperUlStyleNone}>
                  <div>© Wunderloop</div>
                </ul>
              </ul>
            </div>
          </div>
        </div>

        {/*Column 1*/}
        <div className="col-lg-3">
          <li className={footerStyles.footerWrapperUlLi}>
            {/*Products Column*/}
            <ul className={footerStyles.footerWrapperUlStyleNone}>
              <div className={footerStyles.black}>
                <FormattedMessage id="products" />
              </div>
            </ul>
            <ul className={footerStyles.footerWrapperUlStyleNone}>
              <FooterNavItem
                href={config.navigationURL_product}
                rel="nofollow noopener noreferrer"
                target=""
              >
                <FormattedMessage id="overview" />
              </FooterNavItem>
              <FooterNavItem
                href={config.navigationURL_feature}
                rel="nofollow noopener noreferrer"
                target=""
              >
                <FormattedMessage id="feature" />
              </FooterNavItem>
              <FooterNavItem
                href={config.navigationURL_solution_occupancy}
                rel="nofollow noopener noreferrer"
                target=""
              >
                <FormattedMessage id="occupancy" />
              </FooterNavItem>
              
              <FooterNavItem
                href={config.navigationURL_mobileapp}
                rel="nofollow noopener noreferrer"
                target=""
              >
                <FormattedMessage id="workplace_mobile_app" />
              </FooterNavItem>
              <FooterNavItem
                href={config.navigationURL_pricing}
                rel="nofollow noopener noreferrer"
                target=""
              >
                <FormattedMessage id="pricing" />
              </FooterNavItem>
            </ul>
          </li>
        </div>

        {/*Column 2*/}
        <div className="col-lg-3">
          <li className={footerStyles.footerWrapperUlLi}>
            {/*Company Column*/}
            <ul className={footerStyles.footerWrapperUlStyleNone}>
              <div className={footerStyles.black}>Solutions</div>
            </ul>
            <ul className={footerStyles.footerWrapperUlStyleNone}>
              <FooterNavItem
                href={config.navigationURL_solution_deskbooking}
                rel="nofollow noopener noreferrer"
                target=""
              >
                <FormattedMessage id="desk_booking" />
              </FooterNavItem>
              <FooterNavItem
                href={config.navigationURL_solution_roombooking}
                rel="nofollow noopener noreferrer"
                target=""
              >
                <FormattedMessage id="room_booking" />
              </FooterNavItem>
              <FooterNavItem
                href={config.navigationURL_solution_hybridwork}
                rel="nofollow noopener noreferrer"
                target=""
              >
                <FormattedMessage id="hybrid_work" />
              </FooterNavItem>
              <FooterNavItem
                href={config.navigationURL_solution_workexperience}
                rel="nofollow noopener noreferrer"
                target=""
              >
                <FormattedMessage id="work_experience" />
              </FooterNavItem>
              <FooterNavItem
                href={config.navigationURL_solution_space}
                rel="nofollow noopener noreferrer"
                target=""
              >
                <FormattedMessage id="space_managment" />
              </FooterNavItem>
              <FooterNavItem
                href={config.navigationURL_sustainability}
                rel="nofollow noopener noreferrer"
                target=""
              >
                <FormattedMessage id="sustainability" />
              </FooterNavItem>
            </ul>
          </li>
        </div>

        {/*Column 3*/}
        <div className="col-lg-3">
          <li className={footerStyles.footerWrapperUlLi}>
            {/*Resources Column*/}
            <ul className={footerStyles.footerWrapperUlStyleNone}>
              <div className={footerStyles.black}>Company</div>
            </ul>
            <ul className={footerStyles.footerWrapperUlStyleNone}>
              <FooterNavItem
                href={config.navigationURL_aboutUs}
                rel="nofollow noopener noreferrer"
                target=""
              >
                <FormattedMessage id="about" />
              </FooterNavItem>
              <FooterNavItem
                href={config.navigationURL_contactSales}
                rel="nofollow noopener noreferrer"
                target=""
              >
                <FormattedMessage id="contact" />
              </FooterNavItem>
              <FooterNavItem
                href={config.navigationURL_legal_imprint}
                rel="nofollow noopener noreferrer"
                target=""
              >
                <FormattedMessage id="imprint" />
              </FooterNavItem>
              <FooterNavItem
                href={config.navigationURL_support}
                rel="nofollow noopener noreferrer"
                target=""
              >
                <FormattedMessage id="support" />
              </FooterNavItem>
            </ul>
            <ul className={footerStyles.footerWrapperUlStyleNone}>
              <div className={footerStyles.black}>Resources</div>
            </ul>
            <ul className={footerStyles.footerWrapperUlStyleNone}>
              <FooterNavItem
                href={config.navigationURL_blog}
                rel="nofollow noopener noreferrer"
                target=""
              >
                <FormattedMessage id="blog" />
              </FooterNavItem>
              <FooterNavItem
                href={config.navigationURL_sitemap}
                rel="nofollow noopener noreferrer"
                target=""
              >
                <FormattedMessage id="sitemap" />
              </FooterNavItem>
              <FooterNavItem
                href={config.navigationURL_privacy}
                rel="nofollow noopener noreferrer"
                target=""
              >
                <FormattedMessage id="privacy_Terms" />
              </FooterNavItem>
              <FooterNavItem
                href={config.navigationURL_cookie}
                rel="nofollow noopener noreferrer"
                target=""
              >
                <FormattedMessage id="cookie_setting" />
              </FooterNavItem>
            </ul>
          </li>
        </div>
      </div>
    </div>
  </section>
)

export default Footer
