import React from "react"
import SideNavItemStyles from "./sideNavItem.module.scss"
export default function SideNavItem(props) {
  return (
    <li className={SideNavItemStyles.sideNavItem}>
        <a 
           href={props.href}
           rel={props.rel}
           target={props.target}
        >
          {props.children}
        </a>
    </li>
  )
}
