import React from "react"
import layoutStyles from "./layout.module.css"
import Footer from "./Organisms/Footer/Footer"
import Header from "./Organisms/Header/Header"
// import CookieConsent, { Cookies } from "react-cookie-consent";
import CookieConsent from "./Molecules/CookieConsent/CookieConsent"
import { Helmet } from "react-helmet"

export default function Layout(props) {
  debugger
  return (
    <div className={layoutStyles.layout}>
      <Header logoTitle="WUNDERLOOP" />

      {props.children}

      <Helmet>
        <script
          id="cookieyes"
          type="text/javascript"
          src="https://cdn-cookieyes.com/client_data/325123e35f96ae850a7b8309.js"
        ></script>
        <meta name="icon" href="https://wunderloop.io/favicon.ico" />
      </Helmet>

      <Footer
        logoTitle="WUNDERLOOP"
        logoText="Pure Engineering. With a Touch of Magic."
        toButton="/contact"
        buttonText="Get in touch"
        toTerms="/termsofuse"
        toPolicy="/privacypolicy"
        linkOneText="Terms of Use"
        linkTwoText="Privacy Policy"
      />
    </div>
  )
}
