import React from "react"
import HeaderNavItemStyles from "./HeaderNavItem.module.scss"
export default function HeaderNavItem(props) {
  return (
    <li className={HeaderNavItemStyles.headerNavItem}>
      <a href={props.href} rel={props.rel} target={props.target}>
        <span className={HeaderNavItemStyles.heading}>
          {props.children} <span className="sr-only" >(current)</span>
          <p>{props.description}</p>
        </span>
      </a>
    </li>
  )
}
