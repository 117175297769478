import React from 'react';
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

export const SidebarProductData = [
  {
    title: 'Overview',
    path: '/product',
    cName: 'nav-text'
  },
  {
    title: 'Features',
    path: '/feature',
    cName: 'nav-text'
  },
  {
    title: <FormattedMessage id="sustainability"/>,
    path: '/sustainability',
    cName: 'nav-text'
  },
  {
    title: <FormattedMessage id="pricing"/>,
    path: '/pricing',
    cName: 'nav-text'
  }
];

export const SidebarResourceData = [
  {
    title: 'Blog',
    path: '/blog',
    cName: 'nav-text'
  },
  {
    title: <FormattedMessage id="contact"/>,
    path: '/contact/sales',
    cName: 'nav-text'
  },
  {
    title: <FormattedMessage id="about"/>,
    path: '/about',
    cName: 'nav-text'
  },
  {
    title: <FormattedMessage id="support"/>,
    path: '/support',
    cName: 'nav-text'
  }
];