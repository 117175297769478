import React from "react"
import HeaderNavItem from "../../Molecules/HeaderNavItem/HeaderNavItem"
import Button from "../../Atoms/Button/Button"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import "bootstrap/dist/css/bootstrap.min.css"
import "../../../styles/components/header.css"
import headerStyles from "./header.module.scss"
import config, { backgroundColor, themeColor } from "../../../utils/siteConfig"
import SideBar from "../SideBar/SideBar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

const Header = props => {
  return (
    <header
      style={{
        backgroundImage: `url("")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor: "white",
      }}
    >
      <div className={headerStyles.navigationContainer}>
        <nav className={"navbar navbar-expand-lg sticky-top navbar-light"}>
          <div className={headerStyles.logo}>
            <a className="order-first" href={config.navigationURL_home}>
              <img
                src={require("../../../images/Logo/wunderloop_logo.svg")}
                style={{ width: "100%" }}
                alt="Wunderloop logo"
              />
            </a>
          </div>
          <div
            className="collapse navbar-collapse flex-column align-content-center"
            id="navbar"
          >
            <ul className="navbar-nav">
              <li className="nav-item pr-3">
                <div className={headerStyles.dropDownContainer}>
                  <div className={headerStyles.dropDownMenu}>
                    <div className={headerStyles.selecteItem}>
                      <Button
                        className="btns"
                        buttonStyle="btn--navItemLight"
                        buttonSize="btn--huge"
                      >
                        <FormattedMessage id="products" />
                      </Button>
                    </div>
                    <ul>
                      <HeaderNavItem
                        href={config.navigationURL_product}
                        rel="nofollow noopener noreferrer"
                        target=""
                        description={
                          <FormattedMessage id="workplace_Product_Overview" />
                        }
                      >
                        <FormattedMessage id="overview" />
                      </HeaderNavItem>
                      <HeaderNavItem
                        href={config.navigationURL_feature}
                        rel="nofollow noopener noreferrer"
                        target=""
                        description={
                          <FormattedMessage id="workplace_Feature" />
                        }
                      >
                        <FormattedMessage id="feature" />
                      </HeaderNavItem>
                      <HeaderNavItem
                        href={config.navigationURL_solution_occupancy}
                        rel="nofollow noopener noreferrer"
                        target=""
                        description={
                          <FormattedMessage id="workplace_Feature" />
                        }
                      >
                        <FormattedMessage id="occupancy_management" />
                      </HeaderNavItem>
                      <HeaderNavItem
                        href={config.navigationURL_solution_space_utilization}
                        rel="nofollow noopener noreferrer"
                        target=""
                        description={
                          <FormattedMessage id="workplace_Feature" />
                        }
                      >
                        <FormattedMessage id="space_utilization" />
                      </HeaderNavItem>
                      <HeaderNavItem
                        href={config.navigationURL_mobileapp}
                        rel="nofollow noopener noreferrer"
                        target=""
                        description={
                          <FormattedMessage id="workplace_Feature" />
                        }
                      >
                        <FormattedMessage id="workplace_mobile_app" />
                      </HeaderNavItem>
                    </ul>
                  </div>
                </div>
              </li>
              <li className="nav-item pr-1 pl-3">
                <div className={headerStyles.dropDownContainer}>
                  <div className={headerStyles.dropDownMenu}>
                    <div className={headerStyles.selecteItem}>
                      <Button
                        className="btns"
                        buttonStyle="btn--navItemLight"
                        buttonSize="btn--huge"
                      >
                        <FormattedMessage id="solutions" />
                      </Button>
                    </div>
                    <ul>
                      <HeaderNavItem
                        href={config.navigationURL_solution_deskbooking}
                        rel="nofollow noopener noreferrer"
                        target=""
                        description={
                          <FormattedMessage id="workplace_Feature" />
                        }
                      >
                        <FormattedMessage id="desk_booking" />
                      </HeaderNavItem>
                      <HeaderNavItem
                        href={config.navigationURL_solution_roombooking}
                        rel="nofollow noopener noreferrer"
                        target=""
                        description={
                          <FormattedMessage id="workplace_Feature" />
                        }
                      >
                        <FormattedMessage id="room_booking" />
                      </HeaderNavItem>
                      <HeaderNavItem
                        href={config.navigationURL_solution_hybridwork}
                        rel="nofollow noopener noreferrer"
                        target=""
                        description={
                          <FormattedMessage id="workplace_Feature" />
                        }
                      >
                        <FormattedMessage id="hybrid_work" />
                      </HeaderNavItem>
                      <HeaderNavItem
                        href={config.navigationURL_solution_workexperience}
                        rel="nofollow noopener noreferrer"
                        target=""
                        description={
                          <FormattedMessage id="workplace_Feature" />
                        }
                      >
                        <FormattedMessage id="work_experience" />
                      </HeaderNavItem>
                      <HeaderNavItem
                        href={config.navigationURL_solution_space}
                        rel="nofollow noopener noreferrer"
                        target=""
                        description={
                          <FormattedMessage id="workplace_Feature" />
                        }
                      >
                        <FormattedMessage id="space_managment" />
                      </HeaderNavItem>
                      <HeaderNavItem
                        href={config.navigationURL_sustainability}
                        rel="nofollow noopener noreferrer"
                        target=""
                        description={
                          <FormattedMessage id="workplace_Feature" />
                        }
                      >
                        <FormattedMessage id="sustainability" />
                      </HeaderNavItem>
                    </ul>
                  </div>
                </div>
              </li>
              <li className="nav-item pl-4">
                <div className={headerStyles.dropDownContainer}>
                  <div className={headerStyles.dropDownMenu}>
                    <div className={headerStyles.selecteItem}>
                      <Button
                        className="btns"
                        buttonStyle="btn--navItemLight"
                        buttonSize="btn--huge"
                        href={config.navigationURL_pricing}
                      >
                        <FormattedMessage id="pricing" />
                      </Button>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item pl-3">
                <div className={headerStyles.dropDownContainer}>
                  <div className={headerStyles.dropDownMenu}>
                    <div className={headerStyles.selecteItem}>
                      <Button
                        className="btns"
                        buttonStyle="btn--navItemLight"
                        buttonSize="btn--huge"
                        href={config.navigationURL_blog}
                      >
                        <FormattedMessage id="blog" />
                      </Button>
                    </div>
                  </div>
                </div>
              </li>

              <li className="nav-item">
                <div className={headerStyles.dropDownContainer}>
                  <div className={headerStyles.dropDownMenu}>
                    <div className={headerStyles.selecteItem}>
                      <Button
                        className="btns"
                        buttonStyle="btn--navItemLight"
                        buttonSize="btn--huge"
                        href={config.navigationURL_contactSales}
                      >
                        <FormattedMessage id="contact" />
                      </Button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className={headerStyles.signinButton}>
            <Button
              className="btns"
              buttonStyle="btn--navSigninLight"
              buttonSize="btn--signinMedium"
              href={config.navigationURL_register}
            >
              <FormattedMessage id="get_Started" />{" "}
              <FontAwesomeIcon
                size="1x"
                icon={faChevronRight}
                className="faChevronRight"
              />
            </Button>
          </div>
          <div className={headerStyles.listButton}>
            <SideBar />
          </div>
        </nav>
      </div>
    </header>
  )
}

export default injectIntl(Header)

/*<li>
          <div className={headerStyles.dropDownContainer}>
            <div className={headerStyles.dropDownMenu}>
              <div className={headerStyles.selecteItem}>
                <Button
                  className="btns"
                  buttonStyle="btn--outline"
                  buttonSize="btn--medium"
                  href={config.navigationURL_contactUs}
                >
                  Register now
                </Button>
              </div>
            </div>
          </div>
        </li>*/
